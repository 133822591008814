import { appAuth } from "@/firebase";
import CloudFunctionsService from "@/services/cloud_functions_service";
import {
  MemberConnectedAccountTypeFromJSON,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";
import { useQueryParam } from "use-query-params";
import { getRedirectUri } from "../utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useConnectAccount() {
  const navigate = useNavigate();
  const [socialTypeRaw] = useQueryParam<string>("socialType");
  const [authToken] = useQueryParam<string>("authToken");
  const [membershipId] = useQueryParam<string>("membershipId");
  const cloudFunctionsService = CloudFunctionsService.fromAuth(appAuth);
  const redirectUri = getRedirectUri();
  const socialType = MemberConnectedAccountTypeFromJSON(socialTypeRaw);
  const loginState = { socialType, authToken };
  const validParameters = socialType && authToken;

  useEffect(() => handleAuth(), []);

  function handleAuth() {
    if (validParameters) {
      switch (socialType) {
        case MemberConnectedAccountType.YoutubeAccount:
          handleYouTubeAuth();
          break;
        case MemberConnectedAccountType.TwitterAccount:
          window.location.href = getPassportAuthUrl("twitter");
          break;
        case MemberConnectedAccountType.DiscordAccount:
          window.location.href = getPassportAuthUrl("discord");
          break;
        case MemberConnectedAccountType.FacebookAccount:
          window.location.href = getPassportAuthUrl("facebook");
          break;
        case MemberConnectedAccountType.SpotifyAccount:
          window.location.href = getPassportAuthUrl("spotify");
          break;
        case MemberConnectedAccountType.TikTokAccount:
          handleTikTokAuth();
          break;
        case MemberConnectedAccountType.SolanaWallet:
          navigate(`/membership/${membershipId}/solana`);
          break;
        default:
          throw Error("Auth type not implemented");
      }
    }
  }

  function handleYouTubeAuth() {
    const youtubeLoginUrl =
      `https://accounts.google.com/o/oauth2/v2/auth` +
      `?access_type=offline` +
      `&scope=https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email` +
      `&response_type=code` +
      `&client_id=708217036090-ufobb7jn27urhshg52tv7v7ogl07v8nf.apps.googleusercontent.com` +
      `&redirect_uri=${redirectUri}` +
      `&state=${encodeURIComponent(JSON.stringify(loginState))}`;
    window.location.href = youtubeLoginUrl;
  }

  function handleTikTokAuth() {
    // Note: this does not work running locally. Tiktok only allows redirect urls that are valid public https urls.
    // Modifying the list in the tiktok developer settings requires the app be re-reviewed which takes ~3 days to process.
    const tiktokLoginUrl =
      `https://www.tiktok.com/v2/auth/authorize/?client_key=aw51x1soewop9w1z` +
      `&response_type=code` +
      `&scope=user.info.profile,video.list` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&state=${encodeURIComponent(JSON.stringify(loginState))}`;

    window.location.href = tiktokLoginUrl;
  }

  function getPassportAuthUrl(
    platform: "twitter" | "discord" | "facebook" | "spotify",
  ) {
    return cloudFunctionsService.getFetchUrl({
      functionName: "authhandlerv2",
      subroute: `${platform}/auth`,
      hitCloudRunDirectly: true,
      params: { state: encodeURIComponent(JSON.stringify(loginState)) },
    });
  }

  return {
    isError: !validParameters,
  };
}
