import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ToastUtils } from "@/utils/toast_utils";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { oAuthService } from "@/services/services_locator";
import { useConnectedAccountBuilderController } from "./ConnectedAccountBuilderController";
import { MembershipUtils } from "@/utils/membership_utils";
import { useIsAppEmbed } from "@/utils/hooks/use_embedded_options";
import { useGetConnectedAccountSecretToken } from "@/utils/hooks/use_cache";
import { useEffect } from "react";

export function useConnectAccount(accountType: MemberConnectedAccountType) {
  const orgId = useCurrentOrgId();
  const { onCancelCreate, refetchConnectedAccounts } =
    useConnectedAccountBuilderController();
  const isAppEmbed = useIsAppEmbed();
  const secretTokenQuery = useGetConnectedAccountSecretToken({
    orgId,
    createConnectedAccountSecretTokenRequestDto: { accountType },
  });

  useEffect(() => {
    if (secretTokenQuery.error) {
      ToastUtils.showErrorToast(`Error connecting ${accountType}`, {
        includeSupportEmail: true,
      });
    }
  }, [secretTokenQuery.error]);

  async function onConnect(membershipId?: string) {
    if (!secretTokenQuery.data) {
      return;
    }

    try {
      const result = await oAuthService.runMembershipConnectionPopup({
        authToken: secretTokenQuery.data.token,
        accountType,
        isAppEmbed,
        membershipId,
      });
      const error = result?.error;
      if (result === undefined || result?.success) {
        onCancelCreate?.();
        refetchConnectedAccounts();
      } else if (error) {
        ToastUtils.showErrorToast(error, {
          defaultMessage: `Error connecting ${accountType}`,
          formatMessage: MembershipUtils.formatAuthCodeMessage,
        });
      }
    } catch (error) {
      console.error(error);
      ToastUtils.showErrorToast(error, {
        defaultMessage: `Error connecting ${accountType}`,
      });
      throw error;
    }
  }

  return { onConnect, isLoading: secretTokenQuery.isLoading };
}
