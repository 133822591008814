import { AuthState as ProviderAuthState } from "@/providers/auth_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import KazmUtils from "@/utils/utils";
import {
  CreateConnectedAccountDto,
  MemberConnectedAccountType,
  OrgConnectedAccountType,
  StripeDataFromJSONTyped,
} from "@juntochat/internal-api";
import { AuthResultCode } from "@juntochat/kazm-shared";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import { getRedirectUri } from "../utils";

export const membershipConnectionEventSource = "kazm-membership-connection";
export const shopifyConnectionEventSource = "kazm-shopify-connection";
export const connectStateParam = "connectState";

export type AuthState = {
  authToken?: string;
  socialType?: MemberConnectedAccountType;
  orgConnectedAccountType?: OrgConnectedAccountType;
  app?: boolean;
  orgId?: string;
  membershipId?: string;
};

export type MembershipConnectionPopupResult = {
  source: string;
  success: boolean;
  account?: CreateConnectedAccountDto;
  error: unknown;
};

export function useHandleConnect() {
  const [state] = useQueryParam<string>("state");
  const [code] = useQueryParam<string>("code");
  const [connectionSucceeded, setConnectionSucceeded] = useState(false);
  const [connectionError, setConnectionError] = useState<string | undefined>(
    undefined,
  );

  const inPopup = window.opener && window.opener !== window;
  const cloudFunctionsService = useCloudFunctionsService();
  const authProvider = useAuthProvider();
  const navigate = useNavigate();

  async function getAccountData(args: {
    socialType: MemberConnectedAccountType;
  }): Promise<CreateConnectedAccountDto> {
    const { socialType } = args;
    switch (socialType) {
      case MemberConnectedAccountType.TikTokAccount: {
        const tiktokData = await getTikTokData(code);
        return {
          id: tiktokData.tikTokId,
          accountType: MemberConnectedAccountType.TikTokAccount,
          data: {
            tikTok: tiktokData,
          },
        };
      }
      case MemberConnectedAccountType.YoutubeAccount: {
        const youTubeData = await getYouTubeData(code);
        return {
          id: youTubeData.id,
          accountType: MemberConnectedAccountType.YoutubeAccount,
          data: {
            youtube: youTubeData,
          },
        };
      }
      case MemberConnectedAccountType.TwitterAccount:
      case MemberConnectedAccountType.DiscordAccount:
      case MemberConnectedAccountType.FacebookAccount:
      case MemberConnectedAccountType.SpotifyAccount:
        // Connection handled on server
        throw Error("Passport connection failed");
      default:
        throw Error("Unknown social type " + socialType);
    }
  }

  async function getOrgAccountData(args: {
    accountType: OrgConnectedAccountType;
  }) {
    const { accountType } = args;
    switch (accountType) {
      case OrgConnectedAccountType.TiktokAccount:
        return getTikTokData(code);
      case OrgConnectedAccountType.DiscordAccount:
      case OrgConnectedAccountType.TwitterAccount:
      case OrgConnectedAccountType.ShopifyAccount:
      case OrgConnectedAccountType.StripeAccount:
        return JSON.parse(decodeURIComponent(code));
      default:
        throw Error("Org account type unimplemented: " + accountType);
    }
  }

  async function getTikTokData(code: string) {
    const redirectUri = getRedirectUri();
    const tiktokUser = await cloudFunctionsService.handleTiktokAuthCode({
      code,
      redirectUri,
    });

    const {
      displayName,
      id,
      token,
      refreshToken,
      tokenExpirationDate,
      imageUrl,
      username,
      profileWebLink,
      profileDeepLink,
    } = tiktokUser;

    return {
      tikTokId: id,
      displayName,
      username,
      token,
      refreshToken,
      tokenExpirationDate,
      imageUrl,
      profileDeepLink,
      profileWebLink,
      data: JSON.stringify(tiktokUser),
    };
  }

  async function getYouTubeData(code: string) {
    const redirectUri = getRedirectUri();

    return cloudFunctionsService.handleGoogleAuthCode({
      code,
      redirectUri,
    });
  }

  async function handleLoginParams() {
    const { socialType, authToken, orgConnectedAccountType, app, orgId } =
      JSON.parse(decodeURIComponent(state)) as AuthState;

    if (app) {
      handleShopifyAppConnection();
    } else if (orgConnectedAccountType) {
      handleOrgConnection({ accountType: orgConnectedAccountType, orgId });
    } else if (socialType && authToken) {
      handleMemberConnection({ socialType, authToken });
    } else {
      throw Error("Invalid state parameters");
    }
  }

  async function handleShopifyAppConnection() {
    const accountData = await getOrgAccountData({
      accountType: OrgConnectedAccountType.ShopifyAccount,
    });

    window.opener.postMessage(
      {
        target: shopifyConnectionEventSource,
        shopifyData: JSON.stringify(accountData),
      },
      window.location.origin,
    );

    window.close();
  }

  async function handleOrgConnection(args: {
    accountType: OrgConnectedAccountType;
    orgId?: string;
  }) {
    const { orgId, accountType } = args;

    if (accountType === OrgConnectedAccountType.StripeAccount) {
      handleStripeConnection({ orgId });
    } else {
      const accountData = await getOrgAccountData(args);

      window.opener.postMessage(
        { code: JSON.stringify(accountData) },
        window.location.origin,
      );
    }
  }

  async function handleStripeConnection(args: { orgId?: string }) {
    const { orgId } = args;
    if (!orgId) {
      console.error("OrgId is required for stripe account connection");
      navigate(`/projects/${orgId}?${connectStateParam}=error`);
      return;
    }

    try {
      const accountData = JSON.parse(decodeURIComponent(code));

      const account = {
        orgId,
        accountId: accountData.stripe_user_id,
        authData: {
          stripeData: StripeDataFromJSONTyped(accountData, false),
        },
        accountType: OrgConnectedAccountType.StripeAccount,
      };

      await cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerUpsert(
        {
          orgId,
          upsertOrgConnectedAccountDto: {
            ...account,
            id: "",
          },
        },
      );
      navigate(`/projects/${orgId}?${connectStateParam}=success`);
    } catch (e) {
      navigate(`/projects/${orgId}?${connectStateParam}=error`);
    }
  }

  async function handleMemberConnection(args: {
    socialType: MemberConnectedAccountType;
    authToken: string;
  }) {
    const { socialType, authToken } = args;
    try {
      const accountData = await getAccountData({ socialType });

      await cloudFunctionsService.membershipsApi.publicConnectedAccountControllerConnectWithSecretToken(
        {
          connectWithConnectedAccountSecretTokenRequestDto: {
            accountId: accountData.id,
            accountType: accountData.accountType,
            data: accountData.data,
            token: authToken,
          },
        },
      );

      handleConnectionResult({ success: true, account: accountData });
    } catch (e) {
      handleConnectionError(e);
    }
  }

  function handleConnectionResult(args: {
    success: boolean;
    account?: CreateConnectedAccountDto;
    error?: string;
  }) {
    const { success, account, error } = args;
    const result: MembershipConnectionPopupResult = {
      source: membershipConnectionEventSource,
      success,
      account,
      error,
    };

    if (inPopup) {
      window.opener.postMessage(result, window.location.origin);
    } else {
      setConnectionSucceeded(success);
    }
    setConnectionError(error);
  }

  async function handleConnectionError(e: unknown) {
    let message: string;
    if (KazmUtils.isApiResponseError(e)) {
      message = (await e.response.json()).message;
    } else {
      message = (e as { message: string }).message;
    }

    handleConnectionResult({ success: false, error: message });
  }

  useEffect(() => {
    if (code === AuthResultCode.SUCCESS) {
      handleConnectionResult({ success: true });
    } else if (
      [
        AuthResultCode.ALREADY_CONNECTED_ERROR,
        AuthResultCode.INTERNAL_ERROR,
      ].includes(code as AuthResultCode)
    ) {
      handleConnectionResult({ success: false, error: code });
    } else if (
      authProvider.authState !== ProviderAuthState.AUTH_STATE_LOADING
    ) {
      // Only do this once auth state is loaded so that we can avoid corrupting our login state
      handleLoginParams();
    }
  }, [state, code, authProvider.authState]);

  return {
    connectionSucceeded,
    connectionError,
  };
}
