import { ConnectedAccountActionButton } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/common/ConnectedAccountActionButton";

import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { ConnectedAccountContainer } from "./common/ConnectedAccountContainer";
import { useConnectAccount } from "../use_connect_account";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { useParams } from "react-router-dom";

export function SolanaAccountBuilder() {
  const { membershipId } = useParams();
  const { onConnect, isLoading } = useConnectAccount(
    MemberConnectedAccountType.SolanaWallet,
  );

  return (
    <ConnectedAccountContainer>
      {isLoading || !membershipId ? (
        <LoadingSpinner />
      ) : (
        <ConnectedAccountActionButton onClick={() => onConnect(membershipId)}>
          Connect
        </ConnectedAccountActionButton>
      )}
    </ConnectedAccountContainer>
  );
}
