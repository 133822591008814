import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import { OutcomeBuilderConfigProvider } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderConfig";
import { ConnectedAccountBuilderController } from "@/modules/connected_accounts/ConnectedAccountBuilder/ConnectedAccountBuilderController";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import { ToastUtils } from "@/utils/toast_utils";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import classNames from "classnames";
import { SolanaWalletBuilder } from "./SolanaWalletBuilder";

export function SolanaRoute() {
  return <SolanaBuilder />;
}

function SolanaBuilder() {
  const isMobile = useIsMobile();

  return (
    <OutcomeBuilderConfigProvider showIcon={true}>
      <ConnectedAccountBuilderController
        accountType={MemberConnectedAccountType.SolanaWallet}
        isEditable={true}
        onCreateSuccess={() => {
          window.close();
        }}
        onCancelCreate={() => {
          window.close();
        }}
        onCreateError={() => {
          ToastUtils.showErrorToast("Error connecting Solana account");
        }}
      >
        <MembershipPageSection
          title="Connect Wallet"
          className={classNames("h-full w-full", {
            "rounded-none": isMobile,
          })}
        >
          <SolanaWalletBuilder />
        </MembershipPageSection>
      </ConnectedAccountBuilderController>
    </OutcomeBuilderConfigProvider>
  );
}
