import {
  ActionTypeVisitor,
  CommonActionUtils,
  MemberActionType,
} from "@juntochat/kazm-shared";

export enum ActionUsageContext {
  MEMBERSHIP_BASE,
  MEMBERSHIP_TIER,
  QUEST,
  MERCH_REWARD,
  // TODO(activations): Add a unit test for this
  // MERCH_REWARD action types should not overlap with REWARD_PREREQUISITES action types.
  // Otherwise we can't determine which actions to show where in the UI (since we use action type to determine that).
  PREREQUISITES,
  REWARD_REDEMPTION_METHOD,
  FORM,

  // Deprecated - legacy forms
  INVITE_CONNECTION,
  INVITE_REQUIREMENTS,
  INVITE_EXTRA_INFO,
}

export class ActionUsage extends ActionTypeVisitor<
  never,
  ActionUsageContext[]
> {
  // Returns true, if action can be used at most once.
  // Only accepts resolved action types,
  // since we can't determine if unresolved action type is a singleton or not.
  public static isSingletonAction(actionType: MemberActionType) {
    const isSingletonActionLookup: Record<MemberActionType, boolean> = {
      [MemberActionType.RECAPTCHA_V2]: true,
      [MemberActionType.TERMS_OF_SERVICE_AGREEMENT]: true,
      // Prevent having multiple different point threshold requirements
      // within the same action validation "scope".
      [MemberActionType.QUEST_POINTS_THRESHOLD]: true,
      [MemberActionType.EMAIL_CONNECTION]: true,
      [MemberActionType.ETHEREUM_CONNECTION]: true,
      [MemberActionType.SOLANA_CONNECTION]: true,
      [MemberActionType.INSTAGRAM_CONNECTION]: true,
      [MemberActionType.APTOS_CONNECTION]: true,
      [MemberActionType.TWITTER_CONNECTION]: true,
      [MemberActionType.TELEGRAM_CONNECTION]: true,
      [MemberActionType.DISCORD_CONNECTION]: true,
      [MemberActionType.UNSTOPPABLE_DOMAINS_CONNECTION]: true,
      [MemberActionType.TIK_TOK_CONNECTION]: true,
      [MemberActionType.KAZM_MEMBERSHIP_TIER]: true,
      [MemberActionType.FACEBOOK_CONNECTION]: true,
      [MemberActionType.SPOTIFY_CONNECTION]: true,
      [MemberActionType.INSTAGRAM_MEDIA]: false,
      [MemberActionType.INSTAGRAM_FOLLOW]: false,
      [MemberActionType.TIK_TOK_MEDIA]: false,
      [MemberActionType.TIK_TOK_FOLLOW]: false,
      [MemberActionType.ETHEREUM_MINIMUM_BALANCE]: false,
      [MemberActionType.ETHEREUM_OWN_NFT]: false,
      [MemberActionType.ETHEREUM_OWN_TOKEN]: false,
      [MemberActionType.ETHEREUM_OWN_POAP]: false,
      [MemberActionType.SOLANA_OWN_TOKEN]: false,
      [MemberActionType.APTOS_OWN_TOKEN]: false,
      [MemberActionType.APTOS_OWN_NFT]: false,
      [MemberActionType.WALLET_PROVIDE_LIQUIDITY]: false,
      [MemberActionType.TWITTER_FOLLOW]: false,
      [MemberActionType.TWITTER_LIKE_RETWEET]: false,
      [MemberActionType.TWITTER_MENTION]: false,
      [MemberActionType.TWITTER_REACT]: false,
      [MemberActionType.TWITTER_NAME_SUBSTRING]: false,
      [MemberActionType.TWITTER_BIO_SUBSTRING]: false,
      [MemberActionType.TWITTER_PROFILE_PICTURE]: false,
      [MemberActionType.DISCORD_SERVER_JOIN]: false,
      [MemberActionType.DISCORD_HAS_DISCORD_ROLE]: false,
      [MemberActionType.DISCORD_SEND_MESSAGE]: false,
      [MemberActionType.DISCORD_REACTION]: false,
      [MemberActionType.TELEGRAM_SEND_MESSAGE]: false,
      [MemberActionType.TELEGRAM_JOIN_GROUP]: false,
      [MemberActionType.TELEGRAM_JOIN_CHANNEL]: false,
      [MemberActionType.STRIPE_SUBSCRIPTION_VERIFIED]: false,
      [MemberActionType.SPOTIFY_FOLLOW]: false,
      [MemberActionType.SPOTIFY_LISTEN]: false,
      [MemberActionType.LOCATION]: false,
      [MemberActionType.TEXT_INPUT]: false,
      [MemberActionType.MULTIPLE_CHOICE]: false,
      [MemberActionType.PHONE_NUMBER]: false,
      [MemberActionType.REFERRAL]: false,
      [MemberActionType.REFERRAL_BONUS]: false,
      [MemberActionType.UPLOAD_IMAGE]: false,
      [MemberActionType.URL_INPUT]: false,
      [MemberActionType.MANUAL_POINT_ADJUSTMENT]: false,
      [MemberActionType.KAZM_FORM]: false,
      [MemberActionType.KAZM_MEMBERSHIP]: false,
      [MemberActionType.KAZM_API_EVENT]: false,
      [MemberActionType.UNRECOGNIZED]: false,
      [MemberActionType.PROOF_OF_PRESENCE]: false,
      [MemberActionType.VISIT_LINK]: false,
      [MemberActionType.KAZM_QUEST_COMPLETION]: false,
      [MemberActionType.KAZM_BADGE_EARNED]: false,
      [MemberActionType.YOUTUBE_CONNECTION]: false,
      [MemberActionType.YOUTUBE_SUBSCRIBE]: false,
      [MemberActionType.REWARD_DISCORD_ROLE]: false,
      [MemberActionType.REWARD_MERCH]: false,
      [MemberActionType.REWARD_SHOPIFY_DISCOUNT]: false,
      [MemberActionType.REWARD_LINK]: false,
      [MemberActionType.REWARD_QR_CODE]: false,
      [MemberActionType.REWARD_KAZM_DISCOUNT]: false,
      [MemberActionType.KAZM_MEMBER_TAG]: false,
      [MemberActionType.BADGE_CUSTOM]: false,
      [MemberActionType.CHECK_IN]: false,
    };

    return isSingletonActionLookup[actionType];
  }

  public static getActionsForUsageContext(
    context: ActionUsageContext,
  ): MemberActionType[] {
    return CommonActionUtils.getAllActionTypes().filter((actionType) =>
      this.isActionUsedInContext(actionType, context),
    );
  }

  public static isActionUsedInContext(
    actionType: MemberActionType,
    context: ActionUsageContext,
  ): boolean {
    return this.getUsageContextForAction(actionType).includes(context);
  }

  static getUsageContextForAction(actionType: MemberActionType) {
    return new ActionUsage().visit(actionType, undefined as never);
  }

  protected ethereumConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected solanaConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected aptosConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected aptosOwnToken(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected solanaOwnToken(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected aptosOwnNft(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected ethereumOwnToken(): ActionUsageContext[] {
    return [
      ActionUsageContext.INVITE_REQUIREMENTS,
      ActionUsageContext.MEMBERSHIP_TIER,
      ActionUsageContext.QUEST,
    ];
  }

  protected ethereumMinimumBalance(): ActionUsageContext[] {
    return REQUIREMENT_ACTION_USAGE;
  }

  protected ethereumOwnNft(): ActionUsageContext[] {
    return [
      ActionUsageContext.INVITE_REQUIREMENTS,
      ActionUsageContext.MEMBERSHIP_TIER,
      ActionUsageContext.QUEST,
    ];
  }

  protected ethereumOwnPoap(): ActionUsageContext[] {
    return REQUIREMENT_ACTION_USAGE;
  }

  protected walletProvideLiquidity(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected twitterConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected twitterMention(): ActionUsageContext[] {
    return ADVANCED_REQUIREMENT_ACTION_USAGE;
  }

  protected twitterLikeRetweet(): ActionUsageContext[] {
    return LEGACY_TWITTER_REQUIREMENT_USAGE;
  }

  protected twitterFollow(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected twitterReact(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected twitterNameSubstring(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected twitterBioSubstring(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected twitterProfilePicture(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected discordConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected discordServerJoin(): ActionUsageContext[] {
    return [ActionUsageContext.MEMBERSHIP_TIER, ActionUsageContext.QUEST];
  }

  protected discordHasDiscordRole(): ActionUsageContext[] {
    return [ActionUsageContext.MEMBERSHIP_TIER, ActionUsageContext.QUEST];
  }

  protected discordSendMessage(): ActionUsageContext[] {
    return ADVANCED_REQUIREMENT_ACTION_USAGE;
  }

  protected discordReaction(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected instagramConnection() {
    // deprecated
    return [];
  }

  protected instagramMedia() {
    // deprecated
    return [];
  }

  protected tikTokMedia(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected instagramFollow() {
    // deprecated
    return [];
  }

  protected telegramConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected tikTokFollow(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected telegramJoinGroup(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected telegramJoinChannel(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected telegramSendMessage(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected unstoppableDomainsConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected emailConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected stripeSubscriptionVerified(): ActionUsageContext[] {
    return REQUIREMENT_ACTION_USAGE;
  }

  protected location(): ActionUsageContext[] {
    return DATA_COLLECTION_ACTION_USAGE;
  }

  protected phoneNumber(): ActionUsageContext[] {
    return DATA_COLLECTION_ACTION_USAGE;
  }

  protected multipleChoice(): ActionUsageContext[] {
    return VERIFIABLE_INPUTS_USAGE;
  }

  protected textInput(): ActionUsageContext[] {
    return VERIFIABLE_INPUTS_USAGE;
  }

  protected urlInput(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected manualPointAdjustment(): ActionUsageContext[] {
    // Manual point adjustment shouldn't be user-facing
    return HIDDEN;
  }

  protected kazmForm(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected kazmMembership(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected kazmMembershipTier(): ActionUsageContext[] {
    return [
      ...PREREQUISITES_USAGE,
      ActionUsageContext.REWARD_REDEMPTION_METHOD,
    ];
  }

  protected kazmMemberTag(): ActionUsageContext[] {
    return PREREQUISITES_USAGE;
  }

  protected kazmQuestCompletion(): ActionUsageContext[] {
    return [
      ...PREREQUISITES_USAGE,
      ActionUsageContext.REWARD_REDEMPTION_METHOD,
      ActionUsageContext.MEMBERSHIP_TIER,
    ];
  }

  protected kazmBadgeEarned(): ActionUsageContext[] {
    return [
      ...PREREQUISITES_USAGE,
      ActionUsageContext.REWARD_REDEMPTION_METHOD,
    ];
  }

  protected kazmApiEvent(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected reCaptchaV2(): ActionUsageContext[] {
    return BASE_REQUIREMENT_USAGE;
  }

  protected questPointsThreshold(): ActionUsageContext[] {
    return [
      ActionUsageContext.MEMBERSHIP_TIER,
      ActionUsageContext.REWARD_REDEMPTION_METHOD,
      ...PREREQUISITES_USAGE,
    ];
  }

  protected uploadImage(): ActionUsageContext[] {
    return [ActionUsageContext.QUEST];
  }

  protected termsOfServiceAgreement(): ActionUsageContext[] {
    return BASE_REQUIREMENT_USAGE;
  }

  protected referral(): ActionUsageContext[] {
    // For now, this should be used in quests only,
    // since the validation won't work when
    // not triggered in the context of automatic quest claiming.
    // See: https://www.notion.so/kazm/Enable-back-referral-action-for-tier-requirements-5dfb1fd7efbd4af1bf5e948e0ca3210c?pvs=4
    return [ActionUsageContext.QUEST];
  }

  protected referralBonus(): ActionUsageContext[] {
    // For now, this should be used in quests only,
    // since the validation won't work when
    // not triggered in the context of automatic quest claiming.
    // See: https://www.notion.so/kazm/Enable-back-referral-action-for-tier-requirements-5dfb1fd7efbd4af1bf5e948e0ca3210c?pvs=4
    return [ActionUsageContext.QUEST];
  }

  protected youtubeSubscribe(): ActionUsageContext[] {
    return ADVANCED_REQUIREMENT_ACTION_USAGE;
  }

  protected youtubeConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected spotifyConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected spotifyFollow(): ActionUsageContext[] {
    return [];
  }

  protected spotifyListen(): ActionUsageContext[] {
    return [];
  }

  protected proofOfPresence(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected tikTokConnection(): ActionUsageContext[] {
    // Helper functions with predefined references.
    return CONNECTION_ACTION_USAGE;
  }

  protected visitLink(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }

  protected facebookConnection(): ActionUsageContext[] {
    return CONNECTION_ACTION_USAGE;
  }

  protected checkIn(): ActionUsageContext[] {
    return QUEST_ONLY_USAGE;
  }
}

// For action types that shouldn't be shown in any dropdown menu.
const HIDDEN: ActionUsageContext[] = [];

const QUEST_ONLY_USAGE = [ActionUsageContext.QUEST];

// Not available on membership tiers,
// because they have a more complicated outcome builder UI,
// which isn't shown in the tier requirements section
// (we use a separate simplified component there).
const ADVANCED_REQUIREMENT_ACTION_USAGE = [
  ActionUsageContext.INVITE_REQUIREMENTS,
  ActionUsageContext.QUEST,
];

const REQUIREMENT_ACTION_USAGE = [
  ActionUsageContext.INVITE_REQUIREMENTS,
  ActionUsageContext.MEMBERSHIP_TIER,
  ActionUsageContext.QUEST,
  ActionUsageContext.FORM,
];

const CONNECTION_ACTION_USAGE = [
  ActionUsageContext.INVITE_CONNECTION,
  ActionUsageContext.MEMBERSHIP_TIER,
  ActionUsageContext.QUEST,
  ActionUsageContext.FORM,
];

// Only available on forms for legacy reasons.
const LEGACY_TWITTER_REQUIREMENT_USAGE = [
  ActionUsageContext.INVITE_REQUIREMENTS,
];

const DATA_COLLECTION_ACTION_USAGE = [
  ActionUsageContext.INVITE_EXTRA_INFO,
  ActionUsageContext.QUEST,
  ActionUsageContext.MERCH_REWARD,
  ActionUsageContext.FORM,
];

const VERIFIABLE_INPUTS_USAGE = [
  ActionUsageContext.INVITE_EXTRA_INFO,
  ActionUsageContext.QUEST,
  ActionUsageContext.MERCH_REWARD,
  ActionUsageContext.FORM,
];

const PREREQUISITES_USAGE = [ActionUsageContext.PREREQUISITES];

const BASE_REQUIREMENT_USAGE = [
  ActionUsageContext.INVITE_REQUIREMENTS,
  ActionUsageContext.MEMBERSHIP_BASE,
  ActionUsageContext.FORM,
];
