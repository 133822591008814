import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import KazmUtils from "@/utils/utils";
import { OrgConnectedAccountType } from "@juntochat/internal-api";
import { CommonKazmUtils } from "@juntochat/kazm-shared";
import { useState } from "react";
import { ConnectConnectionCard } from "./ConnectConnectionCard";

export function EmptyConnections() {
  const orgId = useCurrentOrgId();
  const [showAllConnections, setShowAllConnections] = useState(false);
  const keys = showAllConnections
    ? Object.keys(OrgConnectedAccountType).sort()
    : Object.keys(OrgConnectedAccountType).sort().slice(0, 5);
  const isShopifyEnabled = !KazmUtils.getEnvParams().isProduction;
  const isSpotifyTestOrg = CommonKazmUtils.isSpotifyTestOrg(orgId);
  const orgConnectionTypes = keys
    .filter((key) => {
      // deprecated
      if (
        OrgConnectedAccountType[key] ===
        OrgConnectedAccountType.InstagramAccount
      ) {
        return false;
      }
      if (
        !isShopifyEnabled &&
        OrgConnectedAccountType[key] === OrgConnectedAccountType.ShopifyAccount
      ) {
        return false;
      }
      if (
        !isSpotifyTestOrg &&
        OrgConnectedAccountType[key] === OrgConnectedAccountType.SpotifyAccount
      ) {
        return false;
      }
      return true;
    })
    .sort();

  return (
    <div className="space-y-[20px]">
      {orgConnectionTypes.map((key) => (
        <ConnectConnectionCard key={key} type={OrgConnectedAccountType[key]} />
      ))}
      {!showAllConnections && (
        <MoreConnectionsCardButton
          handleOnClick={() => setShowAllConnections(true)}
        />
      )}
    </div>
  );
}

interface MoreConnectionsCardButtonProps {
  handleOnClick: () => void;
}

function MoreConnectionsCardButton({
  handleOnClick,
}: MoreConnectionsCardButtonProps) {
  return (
    <UnstyledButton
      className="flex w-full items-center space-x-[14px] rounded-[10px] border-[1.4px] border-dashed border-gray-300 p-[21px]"
      onClick={handleOnClick}
    >
      <KazmIcon.Plus size={28} />
      <div className="text-[19.67px]">More</div>
    </UnstyledButton>
  );
}
