import SizedBox from "@/common_components/SizedBox";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import appImage from "@assets/mock_image/templates/app-mobile.png";
import gamingImage from "@assets/mock_image/templates/gaming-mobile.png";
import musicImage from "@assets/mock_image/templates/music-mobile.png";
import retailImage from "@assets/mock_image/templates/retail-mobile.png";
import web3Image from "@assets/mock_image/templates/web3-mobile.png";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useCreateOrg } from "../../CreateOrgProvider";
import { ActivationTemplateIcon, TemplateIcon } from "./TemplateIcons";

export function OnboardingTemplates() {
  const { onClickSkip } = useCreateOrg();

  return (
    <div className="grid w-full grid-cols-1 gap-2.5 sm:grid-cols-2">
      {TEMPLATE_DATA.map((e) => {
        return <TemplateDisplay template={e} key={e.id} />;
      })}
      <UnstyledButton
        className="flex h-[100px] items-center justify-center rounded-default border border-dashed border-gray-400 bg-dark-base-darker"
        onClick={onClickSkip}
      >
        Start from Scratch
      </UnstyledButton>
    </div>
  );
}

function TemplateDisplay({ template }: { template: TemplateData }) {
  const { selectedTemplateId, setSelectedTemplateId, onClickNext } =
    useCreateOrg();
  const isMobile = useIsSmallMobile();

  return (
    <UnstyledButton
      className="flex h-[100px] items-center gap-[10px] overflow-hidden rounded-default bg-dark-base-darker"
      onClick={() => {
        setSelectedTemplateId(template.id);
        if (isMobile) {
          onClickNext();
        }
      }}
      style={{
        border:
          selectedTemplateId === template.id
            ? `1px solid white`
            : "1px solid transparent",
      }}
    >
      <div
        className="flex h-full w-[100px] flex-shrink-0 flex-col items-end justify-center overflow-hidden"
        style={{ backgroundColor: template.color }}
      >
        <SizedBox height={20} />
        <AccessibleImage src={template.image} />
      </div>
      <div className="flex flex-col items-start gap-[10px] font-semibold">
        {template.label}
        <div
          className={classNames("flex items-center", {
            "gap-3": !isMobile,
            "gap-2": isMobile,
          })}
        >
          {template.icons.map((icon) => (
            <TemplateIcon key={icon} icon={icon} />
          ))}
        </div>
      </div>
    </UnstyledButton>
  );
}

type TemplateData = {
  id: string;
  label: string;
  image: string;
  color: string;
  icons: ActivationTemplateIcon[];
};

export const TEMPLATE_DATA: TemplateData[] = [
  {
    id: "1379e119-68b1-4ff8-87bf-d37eb154618f",
    label: "Gaming",
    image: gamingImage,
    color: AppColors.warmPurple100,
    icons: [
      ActivationTemplateIcon.ANDROID,
      ActivationTemplateIcon.IOS,
      ActivationTemplateIcon.TWITTER,
      ActivationTemplateIcon.TIKTOK,
      ActivationTemplateIcon.TELEGRAM,
      ActivationTemplateIcon.DISORD,
      ActivationTemplateIcon.CART,
      ActivationTemplateIcon.WALLET,
    ],
  },
  {
    id: "8726aa3b-b79a-4c63-a21c-ce1b019795eb",
    label: "Web3",
    image: web3Image,
    color: AppColors.warmPurple100,
    icons: [
      ActivationTemplateIcon.TWITTER,
      ActivationTemplateIcon.TELEGRAM,
      ActivationTemplateIcon.DISORD,
      ActivationTemplateIcon.WALLET,
      ActivationTemplateIcon.GLOBE,
      ActivationTemplateIcon.IOS,
      ActivationTemplateIcon.ANDROID,
      ActivationTemplateIcon.CART,
    ],
  },
  {
    id: "ec185b2f-69e6-42c3-bcdf-267871393986",
    label: "App / Digital Product",
    image: appImage,
    color: "#FBD2F6",
    icons: [
      ActivationTemplateIcon.ANDROID,
      ActivationTemplateIcon.IOS,
      ActivationTemplateIcon.STRIPE,
      ActivationTemplateIcon.TWITTER,
      ActivationTemplateIcon.YOUTUBE,
      ActivationTemplateIcon.DISORD,
      ActivationTemplateIcon.REFERRAL,
    ],
  },
  {
    id: "32878fda-643e-4de0-8677-5145c2b1daa4",
    label: "Music",
    image: musicImage,
    color: "#FDEF50",
    icons: [
      ActivationTemplateIcon.SPOTIFY,
      ActivationTemplateIcon.TICKET,
      ActivationTemplateIcon.PHONE,
      ActivationTemplateIcon.TIKTOK,
      ActivationTemplateIcon.YOUTUBE,
      ActivationTemplateIcon.DISORD,
      ActivationTemplateIcon.CART,
    ],
  },
  {
    id: "86200c15-bfda-4390-98ad-84a4063cc1b2",
    label: "Retail / Ecomm",
    image: retailImage,
    color: AppColors.blue200,
    icons: [
      ActivationTemplateIcon.QR,
      ActivationTemplateIcon.GOOGLE,
      ActivationTemplateIcon.TIKTOK,
      ActivationTemplateIcon.PHONE,
      ActivationTemplateIcon.CART,
      ActivationTemplateIcon.QUESTION,
      ActivationTemplateIcon.REFERRAL,
    ],
  },
];
