import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { ControlledMenu } from "@/common_components/menus/Menu";
import { ConnectedAccountIcon } from "@/modules/connected_accounts/ConnectedAccountIcon/ConnectedAccountIcon";
import { ConnectedAccountTypeName } from "@/modules/connected_accounts/ConnectedAccountTitle/ConnectedAccountTypeName";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { AppColors, CommonKazmUtils } from "@juntochat/kazm-shared";
import { useIsAppEmbed } from "@utils/hooks/use_embedded_options.ts";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import KazmUtils from "@utils/utils.ts";
import { useRef } from "react";

type ConnectedAccountTypesMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  onPick: (accountType: MemberConnectedAccountType) => void;
  boundingRef?: React.RefObject<HTMLElement>;
};

export function ConnectedAccountTypesMenu(
  props: ConnectedAccountTypesMenuProps,
) {
  const ref = useRef(null);
  const { isOpen, onClose, onPick } = props;
  const enabledAccountTypes = useEnabledAccountTypes();

  return (
    <ControlledMenu
      state={isOpen ? "open" : "closed"}
      onClose={() => onClose()}
      direction="top"
      menuStyle={{
        marginTop: 5,
        backgroundColor: AppColors.darkBaseLighter,
      }}
      anchorRef={ref}
      boundingBoxRef={props.boundingRef}
    >
      {enabledAccountTypes.map((accountType) => {
        return (
          <UnstyledButton
            ref={ref}
            key={accountType}
            className="flex h-[35px] w-[210px] cursor-pointer items-center gap-[10px] px-[20px] text-[14px] font-semibold !text-white hover:bg-dark-base"
            onClick={() => onPick(accountType)}
          >
            <ConnectedAccountIcon accountType={accountType} />
            <ConnectedAccountTypeName accountType={accountType} />
          </UnstyledButton>
        );
      })}
    </ControlledMenu>
  );
}

function useEnabledAccountTypes(): MemberConnectedAccountType[] {
  const orgId = useCurrentOrgId();
  // Don't enable for all orgs until spotify application is approved
  const isSpotifyTestOrg = CommonKazmUtils.isSpotifyTestOrg(orgId);
  const { enableYoutubeMemberConnection } = KazmUtils.getEnvParams();

  const enabledAccountTypes: MemberConnectedAccountType[] = [
    MemberConnectedAccountType.EthereumWallet,
    MemberConnectedAccountType.SolanaWallet,
    MemberConnectedAccountType.Email,
    MemberConnectedAccountType.TelegramAccount,
    MemberConnectedAccountType.DiscordAccount,
    MemberConnectedAccountType.TwitterAccount,
    MemberConnectedAccountType.UnstoppableDomain,
    MemberConnectedAccountType.TikTokAccount,
    MemberConnectedAccountType.FacebookAccount,
    MemberConnectedAccountType.AptosWallet,
  ];

  if (enableYoutubeMemberConnection) {
    enabledAccountTypes.push(MemberConnectedAccountType.YoutubeAccount);
  }

  const isAppEmbed = useIsAppEmbed();

  const accountTypesDisabledInEmbed: MemberConnectedAccountType[] = [
    MemberConnectedAccountType.EthereumWallet,
    MemberConnectedAccountType.UnstoppableDomain,
    MemberConnectedAccountType.TelegramAccount,
  ];

  if (isSpotifyTestOrg) {
    enabledAccountTypes.push(MemberConnectedAccountType.SpotifyAccount);
  }

  return isAppEmbed
    ? enabledAccountTypes.filter(
        (e) => !accountTypesDisabledInEmbed.includes(e),
      )
    : enabledAccountTypes;
}
